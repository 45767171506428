<template>
  <div>
<!--    :src="'http://case-h5.yunicu.com/processing?id='+ router_id "      -->
    <iframe
        id="testIframe"
        style="width: 100%;min-height: 1000px;"
        frameborder="0"
        scrolling="no"
        :src="'https://case-h5.yunicu.com/processing?id='+ router_id + '&userId=' + userId + '&userName=' + userName">
    </iframe>
    <div style="width:100%;height:50px;position: fixed;bottom: 50px;background-color: #f0f2f5;border-bottom: 1px solid #fff">
      <div style="width: 80%;display: flex;justify-content: right;margin-top: 10px">
        <a-button @click="returnBtn">返回上一层</a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      router_id:'',
      userId:'',
      userName:'',
    }
  },
  created() {
    this.router_id = this.$route.query.id
    this.userId = this.$route.query.userId
    this.userName = this.$route.query.userName
  },
  methods:{
    returnBtn(){
      window.history.back()
    }
  }
}

</script>